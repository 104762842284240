@import url('https://fonts.googleapis.com/css2?family=Mohave:wght@300;400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Mohave', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #1F1F1F;
    overflow: hidden;
    user-select: none;
    scrollbar-width: thin;
    scrollbar-color: #5A81FA transparent;
}


#Background {
    width: 100vw;
    height: 100vh;
    background-image: url('../imgs/background.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.top_card,
.middle_card,
.bottom_card,
form {
    margin-top: 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card {
    width: 40em;
    max-width: 90vw;
    height: 25em;
    background-color: #F5F5F5;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(43, 49, 138, 0.6);
    backdrop-filter: blur(7.5px);
    border-radius: 40px;
}

form {
    height: auto;
    margin: 0 auto;
    border: 0;
}

input,
select {
    border: 1px solid #CDDEFF;
    border-radius: 22.5px;
    height: 1.5em;
    width: 100%;
    padding: 0 0;
    margin: .5em .5em .5em .5em;
    transform: translate(-1em, 0);
}



select {
    width: 100%;
}

select option {
    background-color: #f7f7f9;
}

input:focus {
    outline: none;
    border: 1px solid #b7cbf3;
}

input[type=checkbox] {
    transform: translate(5.25em, -1.5em);
    width: 1em;
    height: 1em;
    accent-color: #2B318A;
    cursor: pointer;
}

input[type=submit] {
    background: #2B318A;
    border: 0px solid black;
    color: #f7f7f9;
    cursor: pointer;
    transform: translate(0, 0) !important;
}

input[type=search] {
    position: absolute;
    top: .5em;
    left: .5em;
    border-radius: 30px;

    width: 25em;
    max-width: 90%;
    margin: 0 auto !important;
    transform: translate(0, 0);
    background: transparent url("../imgs/icons/lente.svg") no-repeat 95% 50%;
    background-size: 22.5px;
    padding-right: 3.25em;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.pass_eye {
    position: absolute;
    top: .7em;
    right: .1em;
    color: #000;
    cursor: pointer;
    
}

.top_card {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 2.5em;
}

.App-logo {
    height: auto;
    width: 20em;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 2em;
}

.middle_card div {
    position: relative;
    height: 100%;
}

.middle_card label {
    position: absolute;
    top: 0;
    left: -.75em;
    font-size: 80%;
    color: #f7f7f9;
}

.bottom_card {
    color: #f7f7f9;
    text-decoration: none;
    font-size: 80%;
}

.bottom_card a:visited {
    color: #f7f7f9;
    text-decoration: none;
}

.error {
    color: red;
    font-size: 80%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(1em, 0);
}

#Base {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(43, 49, 138);
}

#Menu {
    width: 7.75vw;
    max-width: 10em;
    height: auto;
    position: relative;
}

.menuTop {
    width: 90%;
    height: auto;
    margin: 2em 2.5% 1.5em 2.5%;
}

.menuntent {
    margin: 4%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menuItem {
    width: 100%;
    aspect-ratio: 5 / 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #f7f7f9;
    text-decoration: none;
    border-radius: 15px;
    line-height: 0px;
    color: #A8B1CE;
    transition: .3s;
    font-size: 1em;
}

.menuItem img {
    width: 1.5em;
    height: 1.5em;
}

.menuItem:hover {
    background: rgba(255, 255, 255, 0.1);
}

.menuItemActive {
    background: rgba(255, 255, 255, 0.1);
    aspect-ratio: 6/5;
    padding-top: .6em;
    color: #FFFFFF;
}

#Content {
    background: #FFFFFF;
    /*width: calc(100% - 8.25vw);*/
    /*min-width: calc(100% - 10.5em);*/
    /*height: calc(100% - 1em);*/
    position: absolute;
    /*top: .5em;*/
    /*right: 0;*/
    /*border-radius: 30px;*/
    /*margin-right: .5em;*/

    width: 100%;
    height: 100%;
}

#Header {
    align-items: center;
    /*border-radius: 30px 30px 0 0;*/
    display: flex;
    flex-direction: row;
    font-style: normal;
    /*font-weight: 400;*/
    /*height: 3em;*/
    justify-content: space-between;
    /*padding: 0 1em;*/
    /*top: 0;*/
    /*width: calc(100% - 2em);*/
}

.headerPopup {
    position: relative !important;
}

.headerPopup div {
    cursor: pointer;
}

#Body {
    position: absolute;
    top: 3em;
    right: 0;
    /*width: calc(100% - 1em);*/
    height: calc(100% - 4.5em);
    border-radius: 30px;
    padding: .5em;
}

.fade-in {
    opacity: 0;
    animation: fade-in 1s ease-out forwards;
}

.headerSX {
    display: flex;
    align-items: center;
    font-size: 1.75em;
    /*margin-left: .45em;*/
    margin-left: 3rem !important;
}

.headerCX {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    /*left: 5%;*/
}

.headerDX {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-right: 3rem;
}

.headerDX div,
img {
    cursor: pointer;
}

.mini-selettore {
    font-size: 90%;
    font-weight: 400 !important;
    padding: 0 .75em !important;
    height: 1.25em;
    max-width: 6em;
}

.mini-selettore img {
    font-weight: 800;
}

.nowidth {
    width: auto !important;
}

.nowidth img {
    width: .9em;
}

.selettore {
    position: relative;
    background: #FFFFFF;
    border-radius: 20px;
    line-height: 0;
    max-height: 2.5em;
    /*padding: 0 1.25em;*/
    margin: .15em .25em;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0.4rem;
}

.selettoreinBody {
    position: relative;
    background: #F2F5FF;
    border-radius: 20px;
    line-height: 0;
    max-height: 2.5em;
    padding: 0 1.25em;
    margin: .15em .25em;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.selettorePurple {
    background: #2123b6 !important;
    color: #fff;
}

.selettoreBlue {
    background: #2123b6 !important;
    color: #fff;
}

.selettoreViolet {
    background: #F2F5FF !important;
    color: #2123b6;
    font-weight: 600;
}

.selettoreGrey {
    display: block;
}

.selettoreFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    float: left;
    width: auto;
    min-width: 10em;
}

.displayContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    float: right;
    width: 2em;
}

.selettoreMiddle {
    justify-content: center !important;
    width: 7.5em;
    cursor: initial;
}

.selettoreBorder {
    border: 1px solid #CDDEFF;
    background-color: #fff !important;
    color: #2123b6;
    font-weight: 600;
}
.selettoreBorderAlert {
    border: 1px solid #CDDEFF;
    background-color: #fff !important;
    color: #c10a0a;
    font-weight: 600;
    box-shadow: 0px 3px 10px rgba(90, 129, 250, 0.8);
}

.selettoreShadow {
    box-shadow: 0px 3px 10px rgba(90, 129, 250, 0.8);
}

.selettoreRound {
    border-radius: 50%;
    border: 1px solid #CDDEFF;
    background: #fff;
    padding: .5em;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    margin: .15em;
    cursor: pointer;
}

.selettoreHeader {
    line-height: 0 !important;
    font-size: 90%;
}

.disabled {
    opacity: .25;
    cursor: not-allowed;
}

.visualizzazione {
    background-color: #2B318A;
    border: 0 solid;
    box-shadow: 0px 3px 10px rgba(90, 129, 250, 0.8);
}

.sottoSelettore {
    background: #fff;
    border: 1px solid #cddeff;
    border-radius: 15px;
    box-shadow: 0 8px 10px rgba(43,49,138,.2);
    display: none;
    height: auto;
    left: 0;
    max-height: 15em;
    opacity: 0;
    overflow: auto;
    padding: 0.5em 1em;
    position: absolute;
    top: 2.95em;
    transition: .2s;
    width: calc(100% - 2em);
    overflow-x: hidden;
    z-index: 9!important;
}


.sottoSelettore>.row {
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.5em;
    color: #A8B1CE;
    transition: .2s;
}

.sottoSelettore>.row img {
    transition: .2s;
}

.sottoSelettore>.row:hover {
    color: #2123b6;
    font-weight: 600;
}

.sottoSelettore>.row:hover img {
    padding-left: .5em;
}
.noCursor {
    cursor: inherit !important;
}

.bell {
    border-radius: 20px;
    padding: .25em;
    transition: .4s;
}

.bell:hover {
    background: rgba(250, 0, 0, 0.1)
}


.notifiche {
    background-color: #CE2B37;
    border-radius: 20px;
    width: 7.5px;
    height: 7.5px;
    position: absolute;
    top: .5em;
    left: 1.15em;
}

#ContentPage {
    width: 100%;
    height: calc(100% - 2.5em);
    display: flexbox;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    /*top: 2.4em;*/
    top: 1em;
    overflow: scroll;
    border-radius: 0 0 20px 20px;
    color: #000;
    scrollbar-width: none;

}

#Griglia,
#GrigliaAlarm {
    /* width: 12.25em;
    height: 12em; RIATTIVARE PER TEMPERATURA STANZA*/
    /*width: 10%;*/
    height: 300px;
    padding: 0em;
    border-radius: 20px;
    float: left;
    margin: .25em;
    line-height: 12px;
    /*font-size: 90%;*/
    position: inherit;
    cursor: pointer;
    border: 1px solid transparent;
    transition: .2s;
    max-width: 230px;
    background: #fff;
    box-shadow: 0px 3px 10px rgba(90, 129, 250, 0.8);
    text-overflow: ellipsis;
}

#Griglia:hover,
#GrigliaAlarm:hover {
    border: 1px solid #CDDEFF;

}

#Griglia {
    box-shadow: 0px 5px 8px rgba(43, 49, 138, 0.3);
}

#GrigliaAlarm {
    box-shadow: 0px 8px 15px rgba(43, 49, 138, 0.6);
}

#Griglia table,
#GrigliaAlarm table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 20px;
}
#Griglia select {
    width: auto;
    border: 0;
    border-radius: 20px;
    height: 2.5em;
    padding: 0 1em;
    margin: 1em 0 1em 0;
    transform: translate(-1em, 0);
}

#Griglia td,
#GrigliaAlarm td {
    padding: 1%;
    text-align: center;
    font-size: 90%;
    height: 30px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    font-weight: 300;
    line-height: 20px;
    font-weight: 300;
    color: #3a3e4b;
    
}

#ListaResidenti {
    width: 100%;
    height: 65%;
    overflow: auto;
}

#ListaAmbienti {
    width: 100%;
    height: 35%;
    overflow: auto;
}

#Lista,
#TitoliLista,
.scheda thead {
    width: calc(100% - .25em);
    font-weight: 300 !important;
    font-size: 18px;
    padding-left: .25em;
    line-height: 15px;
    font-size: 95%;
}

#TitoliLista {
    color: #2B318A;
    position: sticky;
    background: #fff;
    top: 0;
    z-index: 1;
    font-size: 90%;
    border-top: 1px solid #F5F5F5;
    border-bottom: 1px solid #CDDEFF;
}

#TitoliLista td {
    vertical-align: middle;
    line-height: 100%;
    padding: .15em;
    
}

.errorBox {
    font-size: 80%;
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: left;
    align-items: center;
}

#Lista {
    height: 2.65em;
    border-bottom: 1px solid #F5F5F5;
}

#Lista td,
#TitoliLista td {
    text-align: left;
    padding-left: .25em;
    font-weight: 300;
    position: relative;
    z-index: 0 !important;
}

button {
    border-radius: 20px;
    border: 0;
    padding: .5em 1em;
    font-size: 90%;
    font-weight: 300;
    color: #fff;
    background: #2B318A;
    transition: .2s;
    cursor: pointer;
    
}

/*i want modify a class "react-switch-handle inside "react-switch" class*/
.react-switch {
    position: relative;
    display: flexbox;
    justify-content: flex-start;
    align-items: center;
}

.react-switch-handle {
    position: absolute;
    top: 100;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 20px;
    background-color: #fff;
    padding: auto;;
    -webkit-transition: .4s;
    transition: .4s;

    box-shadow: 0px 3px 10px rgba(90, 129, 250, 0.8);
}

.allarme {
    background: #FBE6E5 !important;
    border-radius: 20px;
    margin: .15em 0;
}

.arrowTitoli {
    position: relative;
    top: .1em;
}

.selected {
    font-weight: 600 !important;
}

.left {
    float: left;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1em !important;
}

.right {
    float: right;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.alarm,
.alarmG {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 85%;
    font-weight: 500;
}

.alarm img {
    margin-right: .05em;
    margin-top: .15em;
    height: 90%;
}

.alarmG img {
    margin-right: -.25em;
    margin-top: .25em;
    height: 140%;
}

.dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    right: 1.5em;
}

.topLeft {
    position: absolute !important;
    top: 0;
    left: 0;
}

.medium {
    width: 7.5px !important;
    height: 7.5px !important;
}

.big {
    width: 10px !important;
    height: 10px !important;
}

.red {
    background: #FF5149;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 4px #FF5149;
}

.green {
    background: #18FFA9;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 4px #18FFA9;
}

.yellow {
    background: #FFE500;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 4px #FFE500;
}

.blue {
    background: #5A81FA;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 5px #5A81FA;
}

.bgRed {
    background: #FBE6E5;
}

.bgYellow {
    background: #FEF9E2;
}

#Popup,
#PopupHeader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(43, 49, 138, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
    
}

#PopupBody {
    position: relative;
    width: calc(100% - 1em);
    height: auto;
    padding: .5em;
    border-radius: 20px;
    display: flexbox;    
    
    scrollbar-width: none;
}
#PopupBody2 {
  display: flex;
  flex-direction: row; 
  flex-wrap: wrap;
  justify-content: left;
  padding-top: 1.4rem !important;
  width: 100%;
  /*height: 100%;*/
  padding: .5em;
  border-radius: 20px;

    margin-top: 20px;
    overflow-x: auto;

}

#bedroom {
    position: absolute;
    /* border-radius: 20px; */
    background-color: #a2b9ff;
    /* border: 2px solid rgb(43, 43, 190); */
    /* background-image: 
                repeating-linear-gradient(0deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px),
                repeating-linear-gradient(90deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px),
                linear-gradient(90deg, #5A81FA, #5A81FA); */
    -webkit-box-shadow:inset 0px 0px 0px 2px rgb(43, 43, 190);
    -moz-box-shadow:inset 0px 0px 0px 2px rgb(43, 43, 190);
    box-shadow:inset 0px 0px 0px 2px rgb(43, 43, 190);
}

        .horizontal-line {
            position: absolute;
            border: 1px dashed red;
            height: 0; /* Height of the horizontal line */
             /* Hides the horizontal line text */
        }

        .vertical-line {     
            position: absolute;
            border: 1px dashed red; 
            width: 0; /* Width of the vertical line */
        }

.container-line {
    position: absolute;
    background-color: transparent;
    /* border-radius: 20px; */
    border: 1px dashed black;; 
}

.bed {
    /* background-color: #b7cbf3;
    border-radius: 20px; */
    position: absolute;
    border: 1px dashed black;
}


.popupCardContainer {
    width: 100% !important;
    height: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.popupCardContainer::-webkit-scrollbar {
    display: none;
}

.popupCard {
    width: auto;
    height: auto;
    min-height: 20em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 .5em 1em .5em;
    border-radius: 22.5px;
}

.popupNavigator {
    width: 100%;
    height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .5em;
    position: absolute;
    bottom: 0;
    left: 0;
}

.popupNavigator img {
    filter: opacity(0.5);
}

.popupNavigator img:hover {
    filter: opacity(1);
}

.popupNavigatorArrow{
    margin: 0 0 0 1em;
    opacity: .5;
}

.popupNavigatorArrow:hover{
    opacity: 1;
}


#MiniPopup {
    position: absolute;
    top: 4.25em;
    z-index: 100;
    background: #F2F5FF;
    box-shadow: 0px 10px 40px #2B318A;
    border-radius: 22.5px;
    padding: .5em;
    width: 15em;
    display: flex;
    flex-direction: column;
}

#MiniPopup input {
    width: 87.5%;
    position: relative;
    top: .5em;
    left: 0;
    margin: .75em 0 0 0;
    transform: translate(0)
}

#MiniPopup label {
    color: #2B318A !important;
    font-weight: 200;
    font-size: 90%;
    position: relative;
    left: .5em;
}

.minipopTable td {
    padding: 0 !important;
}

.minipopTable .selettore {
    width: 80%;
    margin-top: .5em;
}


.popupContent {
    /*max-width: calc(100% - 6em);*/
    max-width: calc(100% - 5em);
    max-height: calc(100% - 6em);
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 10px 40px #2B318A;
    /*margin-left: 5em;*/
    position: relative;
    overflow: auto;
    overflow-x: hidden;
    scrollbar-width: none;

}

.closePopup {
    position: absolute;
    top: 1em;
    right: 1.5em;
    padding: .5em;
    border-radius: 50%;
}

.closePopup:hover {
    background-color: rgba(200, 200, 200, 0.2);
}

#options {
    width: 8em;
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    padding: .25em;
    margin: 0 .5em;
    z-index: 1;
    position: absolute;
    top: -1.3em;
    left: 50%;
    transform: translate(-50%, 0);
    transition: .2s ease-in-out;
}

#options:hover {
    background: #F2F5FF;
    top: 0;
}

#handle {
    width: 100%;
    height: 4em;
    border-radius: 20px 20px 0 0;
    background-color: #F2F5FF;
    position: relative;
    top: 0;
    left: 0;
    cursor: grab;
    display: flex;
    align-items: center;
}

#handle:active {
    cursor: grabbing;
}

#PopupFooter {
    width: 100%;
    height: 2.5em;
    margin-top: .5em;
    position: relative;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#ContentStruttura {
    width: 100%;
    height: calc(100% - 2em);
    margin: 0;
    padding: 0;
    position: relative;
}

#Strutture {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
}

#Magazzino {
    width: auto;
    height: calc(100% - .25em);
    margin-top: 0.25em;
    padding: 0;
    color: #fff;
    z-index: 2;
    position: absolute;
    top: 0;
    right: .15em;
}

.headerMagazzino {
    background: #5A81FA;
    border-radius: 20px;
    width: 1.75em;
    height: calc(100% - 2em);
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
}

.headerMagazzino img {
    display: none;
}

.headerMagazzino p {
    transform: rotate(-90deg);
    margin-bottom: 3em;
    transition: .2s;
}

.contentMagazzino {
    width: 0;
    height: 100%;
    float: right;
    margin-left: .35em;
    overflow: hidden;
    background: rgba(90, 129, 250, 0.7);
    backdrop-filter: blur(15px);
    border-radius: 20px;
    transition: .4s;
    position: relative;
    right: .25em;
    overflow: auto;
}

.contentMagazzino::-webkit-scrollbar-track {
    margin: 1em;
}

.tabMagazzino {
    width: calc(100%- 2em);
    padding: .5em;
    margin: .5em;
    background-color: rgb(242, 245, 255);
    border-radius: 15px;
    color: #2B318A;
}

.tabConfig {
    height: auto;
    max-height: 2.6em;
    overflow: hidden;
    float: left;
    transition: .4s;
    position: relative;
    padding: 0;
    margin-top: .25em;
    color: #2B318A;
}

.region,
.piano {
    width: calc(100% - 2.25em);
}

.subregions,
.subregion,
.ala,
.corridoio {
    float: right;
    width: calc(100% - 2em);
}

.stanza {
    float: right;
    width: calc(100% - 2em) !important;
    display: flex;
    align-items: center;
    flex-direction: flex-start;
    margin: .15em 0;
}

.add {
    cursor: pointer;
    border: 0 !important;
    font-weight: 800;
    display: none;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    border-right: 4px solid #fff;
    padding-left: 1em;
    padding-right: .25em;
    position: relative;
}

.add img {
    margin: 0 1em;
}

.add2 {
    border: 0 !important;
    font-weight: 800;
    height: 100%;
    display: none;
    justify-content: space-between;
    align-items: center;
    border-right: 4px solid #fff;
    padding-left: 1.1em;
    padding-right: .25em;
    position: relative;
    width: 22em;
}

.add2 img {
    width: 1em;
}

.add2 div {
    font-weight: 200;
}

.headerStruttura {
    width: calc(100% - .15em);
    height: 2.6em;
    border-radius: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    position: relative;
}

.mini-tab {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 4px solid #fff;
    padding-left: 1em;
    padding-right: .25em;
    position: relative;
}

.mini-tab div {
    display: flex;
    align-items: center;
}

.modifica {
    background: rgba(43, 49, 138, 0.4);
    backdrop-filter: blur(4px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2 !important;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
}

.hov {
    opacity: 0;
}


.hov:hover {
    opacity: 1;
}

.mini-tab-stanza {
    border-right: 1px solid #F5F5F5;
    font-weight: 500;
}
.mini-region,
.mini-piano {
    min-width: 22em !important;
    border-radius: 20px 0 0 20px;
}
.mini-subregion,
.mini-ala {
    min-width: 18em !important;
    border-radius: 20px 0 0 20px;
}

.mini-corridoio {
    min-width: 18em !important;
    border-radius: 20px 0 0 20px;
}

.mini-right {
    height: 1.25em;
    width: 1.25em;
    float: right;
    position: relative;
}

.mini-right img {
    width: 1.25em;
}

.scheda {
    height: auto;
    min-height: 250px;
    position: relative;
    transition: .4s;
    width: 50%;
    margin: 1em 0;
}

.scheda td {
    padding: 1;
    border: 1px solid #F5F5F5;
    border-radius: 10px;
}

.scheda div {
    
    border-radius: 10px;
    /* allign the bedroom component to the right of tbody */
    color: tomato;
    margin: 0 1em;
}
.bedroom-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 0%;
    position: relative;
    
  }
.bedroom-edit-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 0%;
    
  }

.table-container {
    padding-top: 0%;
    margin-right: 20px; /* Adjust the spacing between the table and bedroom as needed */
  }
.table-container td {
    padding: 4px;
    padding-left: 3%;
  }
.table-container select {
    padding: 0;
    padding-left: 2%;

}
.table-container input {
    padding: 1;
    padding-left: 2%;
}
.edit-container {
    /* set in bottom to the right of the previous component*/
    position: relative;
    padding-top: 240px;
    padding-left: 0%;

  }



.scheda label {
    position: relative;
    top: 1.25em;
    color: #000 !important;
    font-size: 85%;
    font-weight: 200;
}

.schedaSotto {
    height: auto;
    min-height: 50px;
    position: relative;
    top: 1.5em;
    transition: .4s;
    width: 97.5%;
    margin-bottom: .5em;
}

.schedaImg img {
    width: 100%;
    height: 100%;
}

.navigators {
    width: 100%;
    height: 2em;
}

#noData {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#noData p {
    font-size: 1.5em;
    font-weight: 500;
    color: #2B318A;
    animation: blinker 2.5s linear infinite;
}

.progress-bar-container {
    background-color: rgb(242, 245, 255);
    border-radius: 10px;
    height: 1em;
    width: 100%;
    margin: 2em 0 0 0;
}

.progress-bar {
    background-color: rgba(43, 49, 138);
    border-radius: 10px;
    height: 100%;
    transition: width 1s linear;
}

.main-logo {
    width: 6em;
}




.container {
    width: 1170px;
    max-width: 100%;
    margin: 0 auto;
}

.container-fluid {
    width: 100%;
    max-width: 100%;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* Indichiamo che tutte le classi che iniziano con .col- non posono crescere */
[class*="col-"]{
    flex: 0 0 auto;
}

/* 12 col System */

.col-xs {
    flex-grow: 1;
    flex-basis: 0;
}

.col-xs-1{flex-basis: calc((100% / 12)* 1);max-width:((100% / 12)* 1) ;}
.col-xs-2{flex-basis: calc((100% / 12)* 2);max-width:((100% / 12)* 2) ;}
.col-xs-3{flex-basis: calc((100% / 12)* 3);max-width:((100% / 12)* 3) ;}
.col-xs-4{flex-basis: calc((100% / 12)* 4);max-width:((100% / 12)* 4) ;}
.col-xs-5{flex-basis: calc((100% / 12)* 5);max-width:((100% / 12)* 5) ;}
.col-xs-6{flex-basis: calc((100% / 12)* 6);max-width:((100% / 12)* 6) ;}
.col-xs-7{flex-basis: calc((100% / 12)* 7);max-width:((100% / 12)* 7) ;}
.col-xs-8{flex-basis: calc((100% / 12)* 8);max-width:((100% / 12)* 8) ;}
.col-xs-9{flex-basis: calc((100% / 12)* 9);max-width:((100% / 12)* 9) ;}
.col-xs-10{flex-basis: calc((100% / 12)* 10);max-width:((100% / 12)* 10) ;}
.col-xs-11{flex-basis: calc((100% / 12)* 11);max-width:((100% / 12)* 11) ;}
.col-xs-12{flex-basis: calc((100% / 12)* 12);max-width:((100% / 12)* 12) ;}
.col-xs-offset-1{margin-left:calc((100% / 12)* 1) ;}
.col-xs-offset-2{margin-left:calc((100% / 12)* 2) ;}
.col-xs-offset-3{margin-left:calc((100% / 12)* 3) ;}
.col-xs-offset-4{margin-left:calc((100% / 12)* 4) ;}
.col-xs-offset-5{margin-left:calc((100% / 12)* 5) ;}
.col-xs-offset-6{margin-left:calc((100% / 12)* 6) ;}
.col-xs-offset-7{margin-left:calc((100% / 12)* 7) ;}
.col-xs-offset-8{margin-left:calc((100% / 12)* 8) ;}
.col-xs-offset-9{margin-left:calc((100% / 12)* 9) ;}
.col-xs-offset-10{margin-left:calc((100% / 12)* 10) ;}
.col-xs-offset-11{margin-left:calc((100% / 12)* 11) ;}
.col-xs-offset-12{margin-left:calc((100% / 12)* 12) ;}

@media (min-width:576px) {
    .container {
        width: 540px;
    }
    .col-sm {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-sm-1{flex-basis: calc((100% / 12)* 1);max-width:((100% / 12)* 1) ;}
    .col-sm-2{flex-basis: calc((100% / 12)* 2);max-width:((100% / 12)* 2) ;}
    .col-sm-3{flex-basis: calc((100% / 12)* 3);max-width:((100% / 12)* 3) ;}
    .col-sm-4{flex-basis: calc((100% / 12)* 4);max-width:((100% / 12)* 4) ;}
    .col-sm-5{flex-basis: calc((100% / 12)* 5);max-width:((100% / 12)* 5) ;}
    .col-sm-6{flex-basis: calc((100% / 12)* 6);max-width:((100% / 12)* 6) ;}
    .col-sm-7{flex-basis: calc((100% / 12)* 7);max-width:((100% / 12)* 7) ;}
    .col-sm-8{flex-basis: calc((100% / 12)* 8);max-width:((100% / 12)* 8) ;}
    .col-sm-9{flex-basis: calc((100% / 12)* 9);max-width:((100% / 12)* 9) ;}
    .col-sm-10{flex-basis: calc((100% / 12)* 10);max-width:((100% / 12)* 10) ;}
    .col-sm-11{flex-basis: calc((100% / 12)* 11);max-width:((100% / 12)* 11) ;}
    .col-sm-12{flex-basis: calc((100% / 12)* 12);max-width:((100% / 12)* 12) ;}
    .col-sm-offset-1{margin-left:calc((100% / 12)* 1) ;}
    .col-sm-offset-2{margin-left:calc((100% / 12)* 2) ;}
    .col-sm-offset-3{margin-left:calc((100% / 12)* 3) ;}
    .col-sm-offset-4{margin-left:calc((100% / 12)* 4) ;}
    .col-sm-offset-5{margin-left:calc((100% / 12)* 5) ;}
    .col-sm-offset-6{margin-left:calc((100% / 12)* 6) ;}
    .col-sm-offset-7{margin-left:calc((100% / 12)* 7) ;}
    .col-sm-offset-8{margin-left:calc((100% / 12)* 8) ;}
    .col-sm-offset-9{margin-left:calc((100% / 12)* 9) ;}
    .col-sm-offset-10{margin-left:calc((100% / 12)* 10) ;}
    .col-sm-offset-11{margin-left:calc((100% / 12)* 11) ;}
    .col-sm-offset-12{margin-left:calc((100% / 12)* 12) ;}
}

@media (min-width:796px) {
    .container {
        width: 540px;
    }
    .col-md {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-md-1{flex-basis: calc((100% / 12)* 1);max-width:((100% / 12)* 1);}
    .col-md-2{flex-basis: calc((100% / 12)* 2);max-width:((100% / 12)* 2);}
    .col-md-3{flex-basis: calc((100% / 12)* 3);max-width:((100% / 12)* 3);}
    .col-md-4{flex-basis: calc((100% / 12)* 4);max-width:((100% / 12)* 4);}
    .col-md-5{flex-basis: calc((100% / 12)* 5);max-width:((100% / 12)* 5);}
    .col-md-6{flex-basis: calc((100% / 12)* 6);max-width:((100% / 12)* 6);}
    .col-md-7{flex-basis: calc((100% / 12)* 7);max-width:((100% / 12)* 7);}
    .col-md-8{flex-basis: calc((100% / 12)* 8);max-width:((100% / 12)* 8);}
    .col-md-9{flex-basis: calc((100% / 12)* 9);max-width:((100% / 12)* 9);}
    .col-md-10{flex-basis: calc((100% / 12)* 10);max-width:((100% / 12)* 10 );}
    .col-md-11{flex-basis: calc((100% / 12)* 11);max-width:((100% / 12)* 11 );}
    .col-md-12{flex-basis: calc((100% / 12)* 12);max-width:((100% / 12)* 12 );}
    .col-md-offset-1{margin-left:calc((100% / 12)* 1) ;}
    .col-md-offset-2{margin-left:calc((100% / 12)* 2) ;}
    .col-md-offset-3{margin-left:calc((100% / 12)* 3) ;}
    .col-md-offset-4{margin-left:calc((100% / 12)* 4) ;}
    .col-md-offset-5{margin-left:calc((100% / 12)* 5) ;}
    .col-md-offset-6{margin-left:calc((100% / 12)* 6) ;}
    .col-md-offset-7{margin-left:calc((100% / 12)* 7) ;}
    .col-md-offset-8{margin-left:calc((100% / 12)* 8) ;}
    .col-md-offset-9{margin-left:calc((100% / 12)* 9) ;}
    .col-md-offset-10{margin-left:calc((100% / 12)* 10) ;}
    .col-md-offset-11{margin-left:calc((100% / 12)* 11) ;}
    .col-md-offset-12{margin-left:calc((100% / 12)* 12) ;}
}

@media (min-width:996px) {
    .container {
        width: 540px;
    }
    .col-lg {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-lg-1{flex-basis: calc((100% / 12)* 1);max-width:((100% / 12)* 1) ;}
    .col-lg-2{flex-basis: calc((100% / 12)* 2);max-width:((100% / 12)* 2) ;}
    .col-lg-3{flex-basis: calc((100% / 12)* 3);max-width:((100% / 12)* 3) ;}
    .col-lg-4{flex-basis: calc((100% / 12)* 4);max-width:((100% / 12)* 4) ;}
    .col-lg-5{flex-basis: calc((100% / 12)* 5);max-width:((100% / 12)* 5) ;}
    .col-lg-6{flex-basis: calc((100% / 12)* 6);max-width:((100% / 12)* 6) ;}
    .col-lg-7{flex-basis: calc((100% / 12)* 7);max-width:((100% / 12)* 7) ;}
    .col-lg-8{flex-basis: calc((100% / 12)* 8);max-width:((100% / 12)* 8) ;}
    .col-lg-9{flex-basis: calc((100% / 12)* 9);max-width:((100% / 12)* 9) ;}
    .col-lg-10{flex-basis: calc((100% / 12)* 10);max-width:((100% / 12)* 10) ;}
    .col-lg-11{flex-basis: calc((100% / 12)* 11);max-width:((100% / 12)* 11) ;}
    .col-lg-12{flex-basis: calc((100% / 12)* 12);max-width:((100% / 12)* 12) ;}
    .col-lg-offset-1{margin-left:calc((100% / 12)* 1) ;}
    .col-lg-offset-2{margin-left:calc((100% / 12)* 2) ;}
    .col-lg-offset-3{margin-left:calc((100% / 12)* 3) ;}
    .col-lg-offset-4{margin-left:calc((100% / 12)* 4) ;}
    .col-lg-offset-5{margin-left:calc((100% / 12)* 5) ;}
    .col-lg-offset-6{margin-left:calc((100% / 12)* 6) ;}
    .col-lg-offset-7{margin-left:calc((100% / 12)* 7) ;}
    .col-lg-offset-8{margin-left:calc((100% / 12)* 8) ;}
    .col-lg-offset-9{margin-left:calc((100% / 12)* 9) ;}
    .col-lg-offset-10{margin-left:calc((100% / 12)* 10) ;}
    .col-lg-offset-11{margin-left:calc((100% / 12)* 11) ;}
    .col-lg-offset-12{margin-left:calc((100% / 12)* 12) ;}

}

.justify-content-center {
    justify-content: center;
}

.w-100 {
    width: 100%;
}

.justify-content-space-between {
    justify-content: space-between;
}

.page-h {
    height: calc(100% - 2.5em);
}

.margin-left-2rem {
    margin-left: 2rem;
}

.margin-right-2rem {
    margin-right: 2rem;
}

.padding-1rem {
    padding: 1rem;
}

.padding-0-9rem {
    padding: 0.9rem;
}

@media (max-width:996px) {
    /**{*/
    /*    background-color: #ff5149;*/
    /*}*/
    .headerPopup{
        /*background-color: #ff5149;*/
        max-width: 800px;
        overflow-x:scroll;
    }
}


@media (max-width:578px) {
    /**{*/
    /*    background-color: #ff5149;*/
    /*}*/
    /**{*/
    /*    background-color: #ff5149;*/
    /*}*/

    .headerSX {
        margin-left: 3px !important;
    }

    .headerDX {
        margin-right: 3px !important;
    }


}
@media (max-width:553px) {
    .div-cont {
        margin-right: 0px !important;
    }
}

@media (max-width:523px) {
    .div-cont {
        margin-left: 2.5rem!important;
    }
}

@media (max-width:500px) {
    #Header {
        font-size: small;
    }
}



@keyframes blinker {
    50% {
        opacity: 0;
    }
}

#loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(43, 49, 138, 0.1);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../imgs/loading.png");
    background-repeat: no-repeat;
    background-position: center;
    display: none;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #5A81FA;
    border-radius: 6px;
    border: 3px solid transparent;
}

::-webkit-scrollbar-track {
    margin-top: 3px;
    margin-bottom: 10px;
}